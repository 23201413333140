@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@media screen and (min-width: 480px) {
    .container {
        padding: 0 3rem;
    }
}

.form-select {
    @apply w-full text-sm rounded-lg border-gray-300 focus:ring focus:ring-indigo-400;
}
textarea {
    @apply block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
.h-20 {
    height: 5rem; /* 80px */
}
.h-24 {
    height: 6rem; /* 96px */
}
.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.border-red-600 {
    border-color: rgb(220 38 38);
}
.h-80 {
    height: 20rem; /* 320px */
}
.h-44 {
    height: 11rem;
}
.w-60 {
    width: 15rem; /* 240px */
}
.w-32 {
    width: 8rem; /* 96px */
}
.w-24 {
    width: 6rem; /* 96px */
}
.to-black {
    --tw-gradient-to: #000000;
}
.to-black-500 {
    --tw-gradient-to: #000000b3;
}
.to-black-300 {
    --tw-gradient-to: #00000063;
}
.left-2 {
    left: 0.5rem;
}
.left-14 {
    left: 3.5rem;
}
.px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
}
.py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}
.py-10 {
    padding-top: 2.5rem; /* 40px */
    padding-bottom: 2.5rem; /* 40px */
}
.text-ellipsis {
    text-overflow: ellipsis;
}
.border-r {
    border-right-width: 1px;
}
.scrollbar-none::-webkit-scrollbar {
    display: none;
}

/*design custom scrollbar*/
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f3f3f3;
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: silver;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b91d1d;
}
/*end*/

.overlay {
    @apply absolute top-0 left-0 h-full bg-black/25 rounded-lg transition duration-300 w-full;
}

button:focus {
    outline: none;
}

button:active {
    @apply scale-95;
}

.button-for-image {
    @apply bg-gray-100 p-1.5 rounded-md hover:bg-white absolute z-30 text-gray-600 hover:text-black;
}

.flickity-viewport {
    height: 500px !important;
}

/*admin*/
[role="tab"].active,
[role="tab"].active:hover {
    color: #c81e1e !important;
    border-color: #c81e1e !important;
    font-weight: 500;
}

img.placeholder {
    min-height: 6rem;
    min-width: 195px;
    @apply bg-gray-50;
}

.hover,
.hover svg {
    @apply relative z-20 block outline-none cursor-pointer;
}

.hover:after {
    content: "";
    @apply absolute inset-0 w-8 h-8 m-auto bg-gray-100 rounded-full opacity-0 -z-0;
    left: -11px;
    right: -11px;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
}

.hover:hover:after {
    @apply z-0 scale-100 opacity-100;
}

/*file-menager*/

.h-screen-95 {
    height: 95vh;
}
.h-screen-90 {
    height: 90vh;
}
.h-screen-85 {
    height: 85vh;
}
.border-l {
    border-left-width: 1px;
}
.img-filetype {
    @apply object-cover object-center w-full rounded-md;
}

.tribute-container {
    @apply p-1 z-50 bg-white rounded-md shadow-lg;
}

.tribute-container ul {
    @apply z-50 bg-white rounded-md overflow-hidden;
}

.tribute-container li {
    @apply px-2 py-1.5 cursor-pointer w-32 truncate hover:bg-blue-500 hover:text-white;
}
.tribute-container li.highlight {
    @apply bg-blue-500 text-white;
}

/*slider arrow*/
.splide__arrows {
    display: none;
}
/*line-camp*/
.line-camp{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

