body {
    font-size: .875rem;
    font-family: Poppins, Arial, serif
}

.mdi {
    width: 16px;
    height: auto
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1)
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky
    }
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333
}

.sidebar .nav-link .mdi {
    margin-right: 4px;
    color: #999
}

.sidebar .nav-link.active {
    color: #007bff
}

.sidebar .nav-link.active .mdi, .sidebar .nav-link:hover .mdi {
    color: inherit
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase
}

[role=main] {
    padding-top: 100px
}

.navbar {
    background-color: #fff
}

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: #fff;
    padding-right: 0
}

.footer {
    background-color: #f5f5f5;
    position: absolute;
    bottom: 0;
    width: 100%
}

.footer > .container {
    padding-right: 15px;
    padding-left: 15px
}

.table td, .table th {
    border-top: 1px solid #f5f5f5
}

.table-hover tbody tr:hover, .table-hover tbody tr:hover th {
    background-color: #f1f7ff;
    box-shadow: 0 0 3px 1px #ccc
}

.styled-checkbox {
    position: absolute;
    opacity: 0
}

.styled-checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0
}

.styled-checkbox + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: #f0f3ff;
    border-radius: 3px
}

.styled-checkbox:hover + label:before {
    background: #017bff
}

.styled-checkbox:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, .12)
}

.styled-checkbox:checked + label:before {
    background: #017bff;
    border: 2px solid #017bff
}

.styled-checkbox:disabled + label {
    color: #b8b8b8;
    cursor: auto
}

.styled-checkbox:disabled + label:before {
    box-shadow: none;
    background: #ddd
}

.styled-checkbox:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: #fff;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff, 4px -6px 0 #fff, 4px -8px 0 #fff;
    transform: rotate(45deg)
}

nav {
    width: 100%
}

#upload-file {
    background: #fff;
    border-radius: 5px;
    border: 2px dashed #0087f7;
    border-image: none;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - 80px);
    font-size: 18px;
    color: #646c7f
}

.img-grid {
    object-fit: cover;
    width: 100%;
    height: 100%
}

.img-grid-filetype {
    padding: 20px
}

.img-box {
    position: relative;
    width: 150px;
    height: 230px;
    margin-right: 15px;
    margin-left: 15px;
    border: 1px solid #dedede;
    margin-bottom: 15px
}

.img-box .file-detail {
    padding: 0 5px 0 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.img-container {
    height: 150px;
    width: 100%
}

#loading {
    width: 40px;
    height: auto;
}

#search {
    width: 400px;
    font-size: unset;
    height: calc(1.5em + .75rem - 2px)
}

.img-box.choose.active {
    border: 2px solid #007bff !important
}

tr.choose.active {
    background-color: #f1f7ff
}

.file-container {
    height: calc(65vh - 190px);
    position: relative
}

.file-container.document {
    height: calc(90vh - 190px)
}

.file-container.document embed {
    height: 100%
}

.file-container.document iframe {
    height: 100%;
    border: none;
    width: 100%;
}

.file-container.image {
    background-color: #f6f7f7;
    box-shadow: inset 0 0 2px 2px rgba(167, 170, 173, .1)
}

.file-container .img-grid-filetype {
    max-width: 50%;
    max-height: 85%
}

.file-container audio, .file-container iframe, .file-container video {
    max-width: 100%;
    max-height: 100%
}

#file-view .modal-content {
    min-height: 85vh
}

.file-info {
    border: 1px solid #f1f1f1;
    padding: 5px 10px
}

.file-info p {
    margin-bottom: 0
}

.display-file {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 100%;
    max-width: 100%;
    padding: 5px
}

.display-audio-file {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
}

#file-view .modal-lg {
    width: 90vw;
    max-width: 90vw
}

.mobile-nav .nav-link {
    color: #6c757d
}

.mobile-nav .nav-link.active {
    color: #007bff
}

.mobile-type-div {
    max-width: 200px;
    padding: .1rem .6rem 0 0
}

.mobile-type-div select {
    border: 1px solid #6c757d;
    color: #6c757d
}

#file-loading {
    display: block;
    margin-left: auto;
    margin-right: auto
}

#modify-view {
    padding: 0;
    position: relative
}

@media (min-width: 768px) {
    [role=main] {
        padding-top: 58px
    }

    .file-container {
        height: calc(90vh - 190px)
    }
}

#modify-view .input-group-text {
    position: absolute;
    z-index: 999;
    background: 0 0;
    border: unset
}

#modify-view select {
    height: calc(1.5em + .75rem - 2px);
    padding-left: 19px
}

#modify-view span {
    position: absolute;
    top: 7px;
    left: 6px;
    color: #6c757d
}

#social-bar {
    margin-bottom: 20px;
}

.choose, .embed, .choose td {
    user-select: none;
}

.delete-file {
    position: absolute;
    right: 20px;
}

@media (max-width: 380px) {
    .mobile-type-div {
        max-width: 145px;
        padding: .1rem 0 0
    }

    .navbar-brand {
        min-width: 152px
    }
}

.loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #ffffff;
    overflow: hidden;
}

.indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(10);
}

.indicator svg polyline {
    fill: none;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.indicator svg polyline#back {
    stroke: rgba(214, 214, 214, 0.52);
}

.indicator svg polyline#front {
    stroke: #007bff;
    stroke-dasharray: 12, 36;
    stroke-dashoffset: 48;
    animation: dash 1s linear infinite;
}

.cta {
    position: fixed;
    bottom: 20px;
    right: 30px;
    color: #222;
    font-weight: bold;
}

@-moz-keyframes dash {
    62.5% {
        opacity: 0;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash {
    62.5% {
        opacity: 0;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@-o-keyframes dash {
    62.5% {
        opacity: 0;
    }
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    62.5% {
        opacity: 0;
    }
    to {
        stroke-dashoffset: 0;
    }
}

#fileManager-iframe {
    border: 0;
    width: 100%;
    height: 100%;
}

#filemanager {
    min-height: calc(100vh - 110px);
}

.filemanagerBody {
    height: calc(100vh - 110px);
}

/*#editAssetForm {*/
/*    max-width: 1000px;*/
/*}*/

.center-image {
    margin: 0px auto;
}

.margin-15-auto {
    margin: 15px auto;
}

.overlays {
    position: absolute;
    pointer-events: none;
}

.edit-image {
    width: 100%;
    height: auto;
}
